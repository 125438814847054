* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f5f6ff !important;
}

.css-initialadmin {
  background-color: rgb(252, 242, 243);
}

.css-admin {
  background-color: rgb(231, 239, 255);
}

.css-user {
  background-color: rgb(246, 255, 255);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: none;
  outline: none;
}

input[type="checkbox"] {
  transform: scale(1.4);
}

.css-hover:hover {
  text-decoration: underline;
}

/* scroll */
.css-scroll {
  overflow-y: scroll;
  height: 500px;
}

.css-scroll::-webkit-scrollbar {
  display: none;
}

.css-scroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* products features */
.css-brand{
  font-weight: 900;
  color: #e5e2e2 !important;
}

.product-card {
  width: 300px;
  border-radius: 0.5rem;
  transition: transform 0.3s;
}

.product-card:hover {
  transform: translateY(-0.5rem);
}

.product-card-link:hover .product-card {
  text-decoration: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.product-image {
  object-fit: cover;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.product-name {
  margin-bottom: 0.5rem;
}

.product-description {
  font-size: 0.9rem;
  color: #888888;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product-price {
  color: #616161;
  font-weight: bold;
  font-size: 1.1rem;
}

.special-features>.row {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.special-features {
  width: 100%;
  position: relative;
  min-height: calc(50vh - 10px);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.carousel img {
  border-radius: 0.5rem;
  max-height: 350px;
  object-fit: cover;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.carousel .special-feature-title {
  animation: fadeIn 1s ease-in;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-Shadow: 1px 1px 2px rgb(0, 0, 0);
}

.special-features-title {
  color: #414141;
  font-weight: bold;
  font-size: 3rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  opacity: 0;
  animation: fadeIn 1s ease-in forwards 0.5s;
  text-Shadow: 1px 1px 2px rgb(138, 138, 138);
}
/* footer */

ul.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.list-unstyled a {
  color: #6c757d;
}

ul.list-unstyled a:hover {
  text-decoration: none;
}

.product-view {
  width: 100%;
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
}

.productViewimage img {
  width: auto;
  height: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}

.product-details {
  padding: 20px;
}

.quantity-input {
  width: 100px !important;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.quantity-input .btn {
  border: none;
  background-color: #f8f9fa;
}

.quantity-input .btn:hover {
  background-color: #e9ecef;
}

/* delete image style */
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.image-wrapper {
  position: relative;
  width: 170px;
  height: 200px;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-wrapper button {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  font-weight: bold;
  text-Shadow: 1px 1px 1px rgba(0, 0, 0, 0.548);
}

/* 404 not found */
.flip {
  animation: flip 4s ease-in-out infinite;
  display: inline-block;
  backface-visibility: visible;
  font-size: 10rem;
  color: #616161;
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
  }

  40% {
    transform: perspective(400px) rotateY(-180deg);
  }

  50% {
    transform: perspective(400px) rotateY(-190deg);
  }

  100% {
    transform: perspective(400px) rotateY(-360deg);
  }
}

/* profile image icon*/
.icon-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.icon-container:hover {
  background-color: rgba(0, 0, 0, 0.308);
}

.icon {
  font-size: 1.9rem;
  color: #ffffff44;
}


/* toast */
.css-toast {
  top: 77px !important;
}

/* cartItems */
.css-cartItems {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(250, 129, 129);
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0 0.3rem;
}